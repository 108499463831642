import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  TextField,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  InputAdornment,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  FormControlLabel,
  Checkbox,
  Tooltip
} from "@mui/material";
import defaultUser from "../../../assets/images/default-user.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import attachmentIcon from "../../../assets/images/attachment-icon.svg";
import messageSending from "../../../assets/images/message-sending.svg";
import ApiCalls from "../../../api/customAxios";
import { toast } from "react-toastify";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import docIcon from "../../../assets/images/extension-icon/doc-icon.png";
import Loader from "../../Loader";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import closeIcon from "../../../assets/images/close-icon.svg";
import { getDSaaSCandidateIdFromToken } from "../../../helpers/auth-header";
import ConfirmDialog from "../../../Components/shared/ConfirmDialog";
import { success, danger } from "../../../helpers/message-box";
import noJobs from "../../../assets/images/NoJobsFound.svg";
import { checkIsImage, formatDate, handleSpaces, isMinutesOrHours } from "../../../helpers/helper-data";
import { Helmet } from "react-helmet";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SendIcon from "@mui/icons-material/Send";
import smileIcon from "../../../assets/images/smileIcon.svg";
import Picker from "emoji-picker-react";
import { useSelector } from "react-redux";
import constants from "../../../helpers/constants";
import { ReactComponent as SearchIcon } from "../../../assets/images/new-search-gray-icon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ReactComponent as MessageSentIcon } from "../../../assets/images/message-sent-icon.svg";
import { ReactComponent as MessageFailedIcon } from "../../../assets/images/message-failed-icon.svg";
import { Tooltip as TooltipData, TooltipTrigger, TooltipContent } from "react-tippy";
import TooltipShared from "../../../Components/shared/TooltipShared";
import { ReactComponent as NewCoversation } from "../../../assets/images/new-conver-img.svg";


const ITEM_HEIGHT = 48;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    // "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MessageWindow(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAssessment = Boolean(anchorEl);
  const messageBodyRef = useRef();
  const messageTextFieldRef = useRef();
  const attachmentRef = useRef();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [chats, setChats] = useState([]);
  const [chatsLastMeassage, setChatsLastMessage] = useState("");
  const [messagesJobs, setMessagesJobs] = useState([]);
  const [activeMessageJob, setActiveMessageJob] = useState({});
  const [activeChatIndex, setActiveChatIndex] = useState(0); // by default first chat will be active
  const [activeJobIndex, setActiveJobIndex] = useState(0); // by default first job will be active
  const [currentChat, setCurrentChat] = useState();
  const [currentJob, setCurrentJob] = useState();
  const [messagesGroupedData, setMessagesGroupedData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [fileToSend, setFileToSend] = useState("");
  const [fileName, setFileName] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const [openDeleteChat, setOpenDeleteChat] = useState(false);
  const [openRemoveAttach, setOpenRemoveAttach] = useState(false);
  const [loaddata, setLoaddata] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sendOption, setSendOption] = useState(props.candidate?.isCheckSendSMS ? true : false);
  const [openSendMenu, setOpenSendMenu] = useState(false);
  const inputRef = React.useRef();
  const anchorRef = React.useRef(null);
  const handleToggleSendMenu = () => {
    setOpenSendMenu((prevOpen) => !prevOpen);
  };
  const handleSelectSendOption = (option) => {
    setSendOption(option);
    setOpenSendMenu(false);
  };
  const handleCloseSendMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenSendMenu(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenSendMenu(false);
    } else if (event.key === "Escape") {
      setOpenSendMenu(false);
    }
  }
  const onEmojiClick = (event, emojiObject) => {
    console.log(event.emoji);
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    // setShowPicker(false);
    setCurrentMessage(currentMessage + event.emoji);
    console.log(currentMessage + event.emoji);
    setShowPicker(false);
  };
  const activateFileClick = () => {
    attachmentRef?.current?.click();
  };

  const [anchorE4, setAnchorE4] = useState(null);
  const open4 = Boolean(anchorE4);
  const handleClick4 = (event) => {
    setAnchorE4(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorE4(null);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleDeleteChatHistory = () => {
      ApiCalls.put(
        "candidates/messages/chat-history?candidateId=" +
          props.candidate?.id,
        null
      )
        .then(async(res) => {
          success("Chat history is deleted successfully.");
          setOpenDeleteChat(false);
        })
        .catch((err) => {
          setOpenDeleteChat(false);
        });

      setAnchorEl(null);
  };

  const handleFileUpload = (event) => {
    const _file = event.target.files[0];
    if (_file) {
      setFileToSend(_file);
      setFileName(_file.name);
    }
    handleClose4();
  };

  const isTodayOrYesterday = (date) => {
    const _date = moment.utc(date).toDate();
    if (moment().isSame(_date, "day")) return "Today";
    else if (moment().subtract(1, "days").isSame(_date, "day")) {
      return "Yesterday";
    } else return moment(_date).format("MMM DD, YYYY");
  };


  const isTodayOrYesterdayForChats = (date) => {
    const _date = moment.utc(date).toDate();
    if (moment().isSame(_date, "day")) return moment(_date).format("HH:mm");
    else if (moment().subtract(1, "days").isSame(_date, "day")) {
      return "Yesterday";
    } else return moment(_date).format("MMM DD, YYYY");
  };

  const getOnlyChats = () => {
    // setIsLoading(true);
    ApiCalls.get("enterprises/messages/0/10")
      .then((response) => {
        setChats(response);
        // setIsLoading(false);
      })
      .catch(function (error) {
        if (error.statusCode != 500) {
          danger(<div>{error.errorMessage}</div>);
        }
        // setIsLoading(false);
      });
  };
  const getChats = () => {
    setIsLoading(true);
    ApiCalls.get("enterprises/messages/0/10")
      .then((response) => {
        setChats(response);
        if (
          !currentChat ||
          (response.length > 0 && response[0].jobId !== currentChat.jobId &&
            response[0].messageTo !== currentChat.messageTo)
        ) {
          if (response.length > 0) {
            setCurrentChat(response[0]); // set current chat equal to first chat
            getMessagesJobs(response[0]); //load first chat messages
            // getMessages(response.data[0].messageFrom); //load first chat messages
          } else setIsLoading(false);
        }
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.statusCode != 500) {
          danger(<div>{error.errorMessage}</div>);
        }
        setIsLoading(false);
      });
  };

  const getMessagesJobs = (message) => {
    setIsLoading(true);
    setMessagesJobs([]);
    setValue(0); // this is tab value change
    setActiveJobIndex(0);
    setMessagesGroupedData([]);
    setMessages([]);
    getDSaaSCandidateIdFromToken().then((candidateId) => {
      var messageid = "";
      if (message.messageFrom === candidateId) {
        messageid = message.messageTo;
      } else {
        messageid = message.messageFrom;
      }
      ApiCalls.get("enterprises/messages/jobs" + `?candidateId=${messageid}`)
        .then((response) => {
          setMessagesJobs(response);
          if (response.length > 0) {
            getMessageFromJobs(response[0], message);
          }
          if (response.length === 0) {
            setIsLoading(false);
          }
          // Call first one
          if (document.getElementById("messages_window")) {
          document.getElementById("messages_window").scrollTop =
            document.getElementById("messages_window").scrollHeight;
          }
        })
        .catch(function (error) {
          if (error.statusCode != 500) {
            danger(<div>{error.errorMessage}</div>);
          }
          setIsLoading(false);
        });
      }).catch((err) => {
        setIsLoading(false);
      });
  };

  const getMessages = (candidateId, _isLoading = true) => {
    if (_isLoading) {
      setIsLoading(true);
    }
    ApiCalls.get(
      "candidates/messages/details/0/500?candidateId=" +
      candidateId + "&enterpriseId=" + (localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"))
    )
      .then((response) => {
        var groups = _.groupBy(response, function (item) {
          return moment(item.timestamp).startOf("day").format();
        });
        var result = _.map(groups, function (group, day) {
          return {
            day: day,
            times: group,
          };
        });
        setMessagesGroupedData(result);
        setMessages(response);
        setIsLoading(false);
        if (_isLoading) {
          if (messageBodyRef?.current) {
            messageBodyRef.current.scrollTop = messageBodyRef?.current?.scrollHeight;
          }
          messageTextFieldRef?.current?.focus();
        }
      })
      .catch(function (error) {
        if (error.statusCode != 500) {
          danger(<div>{error.errorMessage}</div>);
        }
        setIsLoading(false);
      });
  };

  const getMessageFromJobs = (messageJob, currentChat) => {
    setActiveMessageJob(messageJob);
    getMessages(currentChat, messageJob.jobId);
  };

  const sendMessageOrFile = () => {
    if (fileToSend) sendFile();
    else sendMessage();
  };

  const readAllMessages = (currentChat) => {
    let formData = {
      messageFrom: currentChat?.id,
      messageTo: localStorage.getItem("candidateId"),
      enterpriseId: (localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId")),
    };
    ApiCalls.put("candidates/messages/read", formData).then((res)=>{
      localStorage.setItem("clearUnreadCountId", currentChat?.id);
    });
  };

  const handleRetrySendSMS = (messageId) => {
    setIsLoading(true);
    ApiCalls.put(`candidates/messages/retry-sms/${messageId}?mobileNumber=${props.candidate?.mobileNumber}`)
    .then((response)=>{
      if (response.statusForFrom === "FAILED") {
        danger("Unable to send sms at this moment. Please try again later.");
        setIsLoading(false);
      } else {
        getMessages(props.candidate?.id);
      }
    }).catch((err)=>{
      setIsLoading(false);
    });
  };

  const sendMessage = () => {
    setIsLoading(true);
      let messageType = sendOption && props.candidate?.mobileNumber ? "SMS" : "TEXT";
      if (currentMessage.trim().length > 0) {
        var formData = new FormData();
        formData.append(
          "messages",
          new Blob(
            [
              JSON.stringify({
                message: currentMessage,
                messageFrom: localStorage.getItem("candidateId"),
                messageTo: props.candidate?.id,
                messageType: messageType,
                phoneNumber: props.candidate?.mobileNumber,
                byEnterprise: true,
                enterpriseId: localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"),
              }),
            ],
            {
              type: "application/json",
            }
          )
        );

        setCurrentMessage("");

        ApiCalls.put("candidates/messages/general", formData)
          .then((response) => {
            localStorage.setItem("isChatHistoryCleared", true);
            var newMessage = response;
            newMessage["lastMessage"] = newMessage.message;
            var updated_messages = [...messages, newMessage];
            var groups = _.groupBy(updated_messages, function (item) {
              return moment(item.timestamp).startOf("day").format();
            });
            var result = _.map(groups, function (group, day) {
              return {
                day: day,
                times: group,
              };
            });
            setMessagesGroupedData(result);
            setMessages(updated_messages);
            setCurrentMessage("");
            setChatsLastMessage(newMessage.message);
            setLoaddata(!loaddata);
            setActiveChatIndex(0);
            setIsLoading(false);
            if (messageBodyRef?.current) {
              messageBodyRef.current.scrollTop = messageBodyRef?.current?.scrollHeight;
            }
            messageTextFieldRef?.current?.focus();
          })
          .catch(function (error) {
            if (error) {
              if (error.statusCode != 500) {
                danger(error.errorMessage);
              }
              // if (error.message === "Request failed with status code 500") {
              //   danger("For some reasons, message could not be sent.");
              // }
            }
            setIsLoading(false);
          });
      }
  };

  const sendFile = () => {
      if (fileToSend) {
        setIsLoading(true);
        var formData = new FormData();
        formData.append(
          "messages",
          new Blob(
            [
              JSON.stringify({
                message: currentMessage,
                messageFrom: localStorage.getItem("candidateId"),
                messageTo: props.candidate?.id,
                messageType: "FILE",
                byEnterprise: true,
                enterpriseId: localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"),
              }),
            ],
            {
              type: "application/json",
            }
          )
        );

        formData.append("file", fileToSend);
        setFileToSend("");
        setFileName("");
        setCurrentMessage("");
        ApiCalls.put("candidates/messages/general", formData)
          .then((response) => {
            localStorage.setItem("isChatHistoryCleared", true);
            var newMessage = response;
            newMessage["lastMessage"] = newMessage.message;
            var updated_messages = [...messages, newMessage];
            var groups = _.groupBy(updated_messages, function (item) {
              return moment(item.timestamp).startOf("day").format();
            });
            var result = _.map(groups, function (group, day) {
              return {
                day: day,
                times: group,
              };
            });
            setMessagesGroupedData(result);
            setMessages(updated_messages);
            setCurrentMessage("");
            setLoaddata(!loaddata);
            setActiveChatIndex(0);
            setIsLoading(false);
            if (messageBodyRef?.current) {
              messageBodyRef.current.scrollTop = messageBodyRef?.current?.scrollHeight;
            }
            messageTextFieldRef?.current?.focus();
          })
          .catch(function (error) {
            if (error) {
              if (error.statusCode != 500) {
                danger(error.errorMessage);
              }
              // if (error.message === "Request failed with status code 500") {
              //   danger("For some reasons, message could not be sent.");
              // }
            }
            setIsLoading(false);
          });
      }
  };
  useEffect(()=>{
    const handleScroll = () => {
      handleClose4();
      if (messageBodyRef.current) {
        const container = messageBodyRef.current;
        const scrollPosition = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        // If the scroll position is at the bottom
        if ((Math.floor(scrollHeight - scrollPosition) === clientHeight) || (Math.ceil(scrollHeight - scrollPosition) === clientHeight)) {
          readAllMessages(props.candidate);
        }
      }
    };
    messageBodyRef.current?.addEventListener('scroll', handleScroll);
    return () => messageBodyRef.current?.removeEventListener('scroll', handleScroll);
  }, [messageBodyRef.current]);
  useEffect(() => {
    if (props.candidate?.id) {
      getMessages(props.candidate?.id);
      const interval = setInterval(() => {
        getMessages(props.candidate?.id, false);
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [loaddata, props.loaddata]);
  useEffect(() => {
    if (messagesJobs.length > 0) {
      // readAllMessages(messagesJobs[0], currentChat, 0);
    }
  }, [messagesJobs]);
  useEffect(()=>{
    props.setMessagesLength(messages?.length);
  }, [messages]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleRemoveAttachment = () => {
    setFileToSend("");
    setFileName("");
    setOpenRemoveAttach(false);
  };
  const handleEnterKey = (e) => {
    setSearchKeyword(e.target.value);
    if (
      e.key === "Enter" &&
      e.target.value !== ""
    ) {
      e.preventDefault();
      search();
    }
    if (
      e.type === "input" &&
      e.target.value === ""
    ) {
      e.preventDefault();
      search(e);
    }
  };
  const clearSearch = async (e) => {
    setSearchKeyword("");
  };
  var onedayBefore = new Date();
  onedayBefore.setDate(onedayBefore.getDate() - 1);
  var currentDateWeekFormat = new Date();
  currentDateWeekFormat.setDate(currentDateWeekFormat.getDate() - 7);

  return (
    <>
        <Grid
          className="pl-0 pr-0 container bg-white resolution-view responsive-tablet-view messageView-align user-detail-align job-view-align galaxy-padding-0" id='message-list'
          sx={{ px: 3 }}
        >
          <Grid container item className="">
            {messages?.length >= 0 && (
              <Grid
                container
                item
                lg={12}
                md={12}
                xs={12}
                className="pl-0"
              >
                <Typography className="border-bottom w-100">
                  {/* <Typography className="matching-tab message-job-list mt-10">
                    <Box>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        className="align-center d-flex"
                        // aria-label="basic tabs example"
                        aria-hidden="false"
                        tabIndex={0}
                      >
                        <Tab
                            onClick={() => {
                              setChatsLastMessage("");
                              // readAllMessages(messageJob, currentChat);
                            }}
                            onKeyPress={() => {
                              setChatsLastMessage("");
                              // readAllMessages(messageJob, currentChat);
                            }}
                            label={
                              <span className="font-weight-600 w-max-content">
                                All ({messagesJobs.unreadMessageCount ? messagesJobs.unreadMessageCount : 0})
                              </span>
                            }
                            {...a11yProps(0)}
                            tabIndex={0}
                          />
                        {messagesJobs.map((messageJob, index) => (
                          <Tab
                            key={index}
                            onClick={() => {
                              setChatsLastMessage("");
                              readAllMessages(messageJob, currentChat, index);
                            }}
                            onKeyPress={() => {
                              setChatsLastMessage("");
                              readAllMessages(messageJob, currentChat, index);
                            }}
                            label={
                              <span className="font-weight-600 w-max-content">
                                {messageJob.jobTitle}
                                <span> - </span>{messageJob.establishmentName} (
                                {messageJob.unreadMessageCount})
                              </span>
                            }
                            {...a11yProps(0)}
                            tabIndex={0}
                          />
                        ))}
                      </Tabs>
                    </Box>
                  </Typography> */}
                </Typography>
                    <Typography
                      id="messages_window"
                      ref={messageBodyRef}
                      className={`w-100 ${messages?.length === 0 ? "p-0" : "p-10"} message-window`}
                      style={{ overflowY: "auto", height: "220px" }}
                    >
                      {isLoading ? (
                        <Grid>
                        {[...Array(12)].map((e, i) => (
                          <Typography className="display-flex align-center" key={i}>
                            <div className="lazy-loader border-none pl-0 pr-0 pt-0 pb-0">
                              <Grid container spacing={1}>
                                <Grid item lg={6} md={6} xs={6} className="d-flex ml-auto">
                                  <Grid className="shimmerBG title-line mx-8 px-8"></Grid>
                                </Grid>
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item lg={5} md={5} xs={5} className="pl-0">
                                  <Grid className="shimmerBG title-line mx-8 px-8"></Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </Typography>
                        ))}
                        </Grid>
                      )
                      :
                      <>
                      {messages?.length === 0 &&
                      <Typography className="text-center">
                        <NewCoversation height="160px" />
                        <Typography className="gray9 f-14 font-weight-600">
                          You're starting a new conversation
                        </Typography>
                        <Box className="gray9 f-12 font-weight-400">
                          Type your first message below.
                        </Box>
                      </Typography>
                      }
                      {messagesGroupedData.map(
                        (messageGroupedDate, indexParent) => (
                          <>
                            <Typography className="justify-center d-flex font-weight-400 f-14 gray9 mb-20">
                              {!isLoading && (
                                <span>
                                  {isTodayOrYesterday(messageGroupedDate.day)}
                                </span>
                              )}
                            </Typography>
                            {messages.map((message, index) =>
                              moment(message?.timestamp)
                                .startOf("day")
                                .format() === messageGroupedDate.day ? (
                                <>
                                  {message.messageFrom !==
                                    localStorage.getItem("candidateId") && (
                                    <Grid item style={{ width: "80%" }}>
                                      <Typography className="d-flex mt-20">
                                        {/* <Typography className="m-r-10">
                                              {(currentChat.messageFromProfileSigned ===
                                                null ||
                                                currentChat.messageFromProfileSigned ===
                                                "null" ||
                                                currentChat.messageFromProfileSigned ===
                                                "") && (
                                                  <Typography className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                                                    width="45px" height="45px" aria-label="Default image">
                                                    {currentChat.messageFromName.trim().substring(0, 1)}
                                                  </Typography>
                                                )}
                                              {currentChat.messageFromProfileSigned !==
                                                null &&
                                                currentChat.messageFromProfileSigned !==
                                                "null" &&
                                                currentChat.messageFromProfileSigned !==
                                                "" &&
                                                !checkIsImage(
                                                  currentChat.messageFromProfileSigned
                                                ) && (
                                                  <video
                                                    width="45px"
                                                    height="45px"
                                                    className="default-img-border profile-video-thumbnail cursor-hover"
                                                    src={
                                                      currentChat.messageFromProfileSigned
                                                    }
                                                    onClick={(e) => {
                                                    }}
                                                    muted
                                                    onKeyPress={(e) => {
                                                    }}
                                                    tabIndex={0}
                                                  />
                                                )}
                                              {currentChat.messageFromProfileSigned !==
                                                null &&
                                                currentChat.messageFromProfileSigned !==
                                                "null" &&
                                                currentChat.messageFromProfileSigned !==
                                                "" &&
                                                checkIsImage(
                                                  currentChat.messageFromProfileSigned
                                                ) && (
                                                  <img
                                                    width="45px"
                                                    height="45px"
                                                    src={
                                                      currentChat.messageFromProfileSigned
                                                    }
                                                    className="default-img-border"
                                                    alt="img"
                                                  />
                                                )}
                                            </Typography> */}
                                        <Typography>
                                          <Typography>
                                            {(message?.messageSignedUrl?.includes(
                                              ".png"
                                            ) ||
                                              message?.messageSignedUrl?.includes(
                                                ".jpg"
                                              ) ||
                                              message?.messageSignedUrl?.includes(
                                                ".jpeg"
                                              )) && (
                                              <Box className="gray9 borderRadius-5 break-word  pt-10 pr-15">
                                                <img
                                                  onClick={() => {
                                                    setCurrentImage(
                                                      message?.messageSignedUrl
                                                    );
                                                    setOpenPreviewModal(true);
                                                  }}
                                                  style={{
                                                    width: "200px",
                                                    cursor: "pointer",
                                                  }}
                                                  src={message.messageSignedUrl}
                                                  alt="img"
                                                  tabIndex={0}
                                                  onKeyPress={() => {
                                                    setCurrentImage(
                                                      message?.messageSignedUrl
                                                    );
                                                    setOpenPreviewModal(true);
                                                  }}
                                                />
                                              </Box>
                                            )}
                                            {message?.messageSignedUrl?.includes(
                                              ".doc"
                                            ) && (
                                              <>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href={
                                                    message.messageSignedUrl
                                                  }
                                                ></a>
                                                <span className="message-pdfReply pt-10 pb-10 pl-15 pr-15 break-word d-block f-14">
                                                  <a
                                                    className="text-decoration-none d-flex break-all align-center"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={
                                                      message.messageSignedUrl
                                                    }
                                                  >
                                                    <img
                                                      style={{ width: "40px" }}
                                                      src={docIcon}
                                                      alt="img"
                                                      className="cursor-hover mr-5"
                                                    />
                                                    {message?.messageSignedUrl &&
                                                      decodeURIComponent(
                                                        message?.messageSignedUrl
                                                          ?.split("/")
                                                          .pop()
                                                          .split("?")[0]
                                                      )}
                                                  </a>
                                                </span>
                                              </>
                                            )}
                                            {message?.messageSignedUrl?.includes(
                                              ".pdf"
                                            ) && (
                                              <>
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href={
                                                    message.messageSignedUrl
                                                  }
                                                ></a>
                                                <span className="message-pdfReply pt-10 pb-10 pl-15 pr-15 break-word d-block f-14">
                                                  <a
                                                    className="text-decoration-none d-flex break-all align-center"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={
                                                      message.messageSignedUrl
                                                    }
                                                  >
                                                    <img
                                                      style={{ width: "40px" }}
                                                      src={Resumepdf}
                                                      alt="img"
                                                      className="cursor-hover mr-5"
                                                    />
                                                    {message?.messageSignedUrl &&
                                                      decodeURIComponent(
                                                        message?.messageSignedUrl
                                                          ?.split("/")
                                                          .pop()
                                                          .split("?")[0]
                                                      )}
                                                  </a>
                                                </span>
                                              </>
                                            )}
                                            {(message?.messageType ===
                                              "TEXT" || message?.messageType === "SMS") && (
                                              <span className="word-break message-text-right align-left pt-10 pb-10 pl-15 pr-15 f-14 d-inline-flex justify-center inputcontrasts">
                                                {message.message}
                                              </span>
                                            )}
                                          </Typography>
                                          <Typography className="d-flex align-baseline mb-5">
                                            {/* <Box className="gray9 f-16 font-weight-500">
                                                  {currentChat.messageFromName}
                                                </Box> */}
                                            {/* <span className="editProfileBullet gray9 ml-15 mr-5" >
                                                  <FiberManualRecordIcon aria-label="Dot icon" />{" "}
                                                </span> */}
                                            <Box className="light-grey f-14 font-weight-400" style={{ fontStyle: "italic" }}>
                                              {message?.messageType === "SMS" &&
                                              <span className="gray7 f-12">
                                                Sent via SMS.{" "}
                                              </span>}
                                              {formatDate(new Date()) ===
                                              formatDate(message?.timestamp) ? (
                                                <span className="gray7 f-12">
                                                  {isMinutesOrHours(
                                                    message?.timestamp
                                                  )}
                                                </span>
                                              ) : (
                                                <span>
                                                  {formatDate(
                                                    currentDateWeekFormat
                                                  ) <=
                                                  formatDate(
                                                    message?.timestamp
                                                  ) ? (
                                                    <span className="gray7 f-12">
                                                      {message?.timestamp ===
                                                        null ||
                                                      message?.timestamp === ""
                                                        ? "-"
                                                        : moment.utc(
                                                            message?.timestamp
                                                          ).local().format("HH:mm")}
                                                    </span>
                                                  ) : (
                                                    <span className="gray7 f-12">
                                                      {message?.timestamp ===
                                                        null ||
                                                      message?.timestamp === ""
                                                        ? "-"
                                                        : moment.utc(
                                                            message?.timestamp
                                                          ).local().format(
                                                            "HH:mm"
                                                          )}
                                                    </span>
                                                  )}
                                                </span>
                                              )}
                                              {/* {moment(
                                                    moment
                                                      .utc(message?.timestamp)
                                                      .toDate()
                                                  ).format("HH:mm")} */}
                                            </Box>
                                          </Typography>
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                  )}
                                  {message.messageFrom ===
                                    localStorage.getItem("candidateId") && (
                                    <Grid
                                      container
                                      item
                                      spacing={1}
                                      className="mt-10 ml-auto justify-end messagelist-resolution"
                                      style={{ width: "80%" }}
                                    >
                                      {/* <Grid item lg={5}></Grid> */}
                                      <Grid>
                                        <Typography className="align-right">
                                          {(message?.messageSignedUrl?.includes(
                                            ".png"
                                          ) ||
                                            message?.messageSignedUrl?.includes(
                                              ".jpg"
                                            ) ||
                                            message?.messageSignedUrl?.includes(
                                              ".jpeg"
                                            )) && (
                                            <span className="pt-10 pl-15 gray9 borderRadius-5 align-right break-word">
                                              <img
                                                onClick={() => {
                                                  setCurrentImage(
                                                    message.messageSignedUrl
                                                  );
                                                  setOpenPreviewModal(true);
                                                }}
                                                style={{
                                                  width: "200px",
                                                  cursor: "pointer",
                                                }}
                                                src={message.messageSignedUrl}
                                                alt="img"
                                                onKeyPress={() => {
                                                  setCurrentImage(
                                                    message.messageSignedUrl
                                                  );
                                                  setOpenPreviewModal(true);
                                                }}
                                                tabIndex={0}
                                              />
                                            </span>
                                          )}
                                          {message?.messageSignedUrl?.includes(
                                            ".doc"
                                          ) && (
                                            <>
                                              <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={message.messageSignedUrl}
                                              ></a>
                                              <span className="message-pdfSend pt-10 pb-10 pl-15 pr-15 align-left break-word d-block f-14">
                                                <a
                                                  className="text-decoration-none d-flex break-all align-center"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href={
                                                    message.messageSignedUrl
                                                  }
                                                >
                                                  <img
                                                    style={{ width: "40px" }}
                                                    src={docIcon}
                                                    alt="img"
                                                    className="cursor-hover mr-5"
                                                  />
                                                  {message?.messageSignedUrl &&
                                                    decodeURIComponent(
                                                      message?.messageSignedUrl
                                                        ?.split("/")
                                                        .pop()
                                                        .split("?")[0]
                                                    )}
                                                  <img
                                                    style={{ display: "none" }}
                                                    className="ml-15 bg-white file-close"
                                                    src={closeIcon}
                                                    alt="Close Icon"
                                                  />
                                                </a>
                                              </span>
                                            </>
                                          )}
                                          {message?.messageSignedUrl?.includes(
                                            ".pdf"
                                          ) && (
                                            <>
                                              <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={message.messageSignedUrl}
                                              ></a>
                                              <span className="message-pdfSend align-left break-word d-block f-14 pt-10 pb-10 pl-15 pr-15">
                                                <a
                                                  className="text-decoration-none d-flex break-all align-center"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href={
                                                    message.messageSignedUrl
                                                  }
                                                >
                                                  <img
                                                    style={{ width: "40px" }}
                                                    src={Resumepdf}
                                                    alt="img"
                                                    className="cursor-hover mr-5"
                                                  />
                                                  {message?.messageSignedUrl &&
                                                    decodeURIComponent(
                                                      message?.messageSignedUrl
                                                        ?.split("/")
                                                        .pop()
                                                        .split("?")[0]
                                                    )}

                                                  <img
                                                    style={{ display: "none" }}
                                                    className="ml-15 bg-white file-close"
                                                    src={closeIcon}
                                                    alt="Close icon"
                                                  />
                                                </a>
                                              </span>
                                            </>
                                          )}
                                          {(message?.messageType === "TEXT" || message?.messageType === "SMS") && (
                                            <span className="word-break message-text align-left pt-10 pb-10 pl-15 pr-15 f-14 d-inline-flex justify-center inputcontrasts">
                                              {message.message}
                                            </span>
                                          )}
                                          <Box className="gray9 f-12 font-weight-400 align-right mb-5" style={{ fontStyle: "italic" }}>
                                            {(message?.statusForFrom === "FAILED" && message?.messageType === "SMS") ?
                                            <>
                                              <span className="danger-color f-12">
                                                Error in sending via SMS.
                                              </span>{" "}
                                              <span className="danger-color f-12 text-decor-underline cursor-hover"
                                                onClick={()=>handleRetrySendSMS(message?.id)}
                                              >
                                                Retry
                                              </span>
                                            </>
                                            :
                                            <>
                                            {message?.messageType === "SMS" &&
                                            <span className="gray7 f-12">
                                              Sent via SMS.{" "}
                                            </span>}
                                            {formatDate(new Date()) ===
                                            formatDate(message?.timestamp) ? (
                                              <span className="gray7 f-12">
                                                {isMinutesOrHours(
                                                  message?.timestamp
                                                )}
                                                {/* {moment(message?.lastMessageTimestamp).fromNow()} */}
                                              </span>
                                            ) : (
                                              <span>
                                                {formatDate(
                                                  currentDateWeekFormat
                                                ) <=
                                                formatDate(
                                                  message?.timestamp
                                                ) ? (
                                                  <span className="gray7 f-12">
                                                    {message?.timestamp ===
                                                      null ||
                                                    message?.timestamp === ""
                                                      ? "-"
                                                      : moment.utc(
                                                          message?.timestamp
                                                        ).local().format("HH:mm")}
                                                  </span>
                                                ) : (
                                                  <span className="gray7 f-12">
                                                    {message?.timestamp ===
                                                      null ||
                                                    message?.timestamp === ""
                                                      ? "-"
                                                      : moment.utc(
                                                          message?.timestamp
                                                        ).local().format(
                                                          "HH:mm"
                                                        )}
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                            </>}
                                          </Box>
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                </>
                              ) : null
                            )}
                          </>
                        )
                      )}
                      </>
                      }
                    </Typography>
                {!fileName && (
                  <Typography className="pt-10 w-100">
                    <Typography className="gray7 f-14 font-weight-400 w-100">
                      <>
                        <TextField
                          variant="outlined"
                          inputRef={messageTextFieldRef}
                          className="chat-textarea position-issue inputcontrasts form-control input-group w-100 textarea-input"
                          type="text"
                          multiline={true}
                          rows={3}
                          value={currentMessage}
                          onChange={(event) =>
                            setCurrentMessage(event.target.value)
                          }
                          onKeyPress={(event) => {
                            handleSpaces(event);
                            if (event.key === "Enter") {
                              event.preventDefault();
                              sendMessage();
                            }
                          }}
                          margin="normal"
                          placeholder="Type your message here"
                          inputProps={{ role: "textbox" }}
                        />
                        {currentMessage?.length > 320 && sendOption && props.candidate?.mobileNumber &&
                        <span className="danger-color error-msg mb-10">
                          {"Exceeded maximum character length of 320"}
                        </span>}
                      </>
                    </Typography>
                    <Typography className={`d-flex align-center ${props.candidate?.mobileNumber ? "justify-between" : "justify-end"} p-relative`}>
                    {props.candidate?.mobileNumber &&
                      <FormControlLabel
                          className="small-text-font font-weight-500 d-flex align-center sent-sms"
                          control={
                            <Checkbox
                              checked={sendOption}
                              onChange={()=>{
                                setSendOption(!sendOption);
                                let value = currentMessage;
                                setCurrentMessage(value?.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu, ''));
                              }}
                              sx={{
                                color: "#98A2B3",
                                "&.Mui-checked": {
                                  color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                },
                              }}
                            />
                          }
                          label={"Send via SMS"}
                        />}
                      <Typography className="d-flex align-center justify-end jobs-ipad">
                      {(!sendOption || !props.candidate?.mobileNumber) &&
                      <>
                        <img
                          style={{ width: "20px", height: '20px', cursor: "pointer" }}
                          onClick={() => setShowPicker((val) => !val)}
                          src={smileIcon}
                          alt="img"
                        />
                          <Grid className="ml-10 d-flex align-center">
                            <input
                              id="contained-button-file"
                              ref={attachmentRef}
                              type="file"
                              accept=".png, .jpg, .jpeg, application/pdf, .doc,.docx"
                              style={{ display: "none" }}
                              onChange={(e) => handleFileUpload(e)}
                            />
                            <a
                              href="javascript:void(0)"
                              onClick={activateFileClick}
                              onKeyPress={activateFileClick}
                              tabIndex={0}
                              className="d-flex vertical-middle gray7 text-decoration-none"
                            >
                              <img
                                style={{ width: "16px", height: '16px' }}
                                className="mr-5 cursor-hover"
                                src={attachmentIcon}
                                alt="img"
                              />
                            </a>
                          </Grid>
                        {/* <Box className="break-all vertical-middle display-flex align-center"> */}
                          {/* <Grid className="">
                            <Button
                              id="basic-button-menu"
                              aria-controls={open ? "account-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              // aria-controls="basic-menu2"
                              //className="dropdown-arrow text-transform-none cursor-hover"
                              style={{ padding: "6px 8px" }}
                              onClick={handleClick4}
                              role="switch"
                              aria-checked={open ? "true" : "false"}
                              tabIndex={0}
                              disableRipple="true"
                              aria-label="button"
                            >
                              <MoreHorizIcon
                                className="f-20 light-black cursor-hover headerIcon inputcontrasts"
                                aria-label="Dropdown icon"
                              />
                            </Button>
                            <Menu
                              style={{ top: "5px" }}
                              id="account-menu"
                              anchorEl={anchorE4}
                              open={open4}
                              className="cursor-hover"
                              onClose={handleClose4}
                              // onClick={handleClose4}
                              disableScrollLock={true}
                              onKeyPress={handleClose4}
                              MenuListProps={{
                                "aria-labelledby": "basic-button-menu",
                              }}
                              Keepmounted
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <MenuItem style={{ padding: "10 10px" }}>
                                <input
                                  id="contained-button-file"
                                  type="file"
                                  accept=".png, .jpg, .jpeg, application/pdf, .doc,.docx"
                                  style={{ display: "none" }}
                                  onChange={(e) => handleFileUpload(e)}
                                />
                                <a
                                  href="javascript:void(0)"
                                  onClick={activateFileClick}
                                  onKeyPress={activateFileClick}
                                  tabIndex={0}
                                  className="d-flex vertical-middle gray7 text-decoration-none"
                                >
                                  <img
                                    style={{ width: "16px" }}
                                    className="mr-5 cursor-hover"
                                    src={attachmentIcon}
                                    alt="img"
                                  />
                                  Attachment
                                </a>
                              </MenuItem>
                            </Menu>
                          </Grid> */}
                          {/* <input
                              id="contained-button-file"
                              type="file"
                              accept=".png, .jpg, .jpeg, application/pdf, .doc,.docx"
                              style={{ display: "none" }}
                              onChange={handleFileUpload}
                            />
                            <a href="javascript:void(0)" onClick={activateFileClick} onKeyPress={activateFileClick} tabIndex={0}>
                              <img style={{ width: "16px", cursor: "pointer" }}
                                src={attachmentIcon}
                                alt="img"
                              /></a> */}
                        {/* </Box> */}
                        </>}
                        <Box>
                          <Button
                            ref={anchorRef}
                            aria-controls={
                              openSendMenu ? "composition-menu" : undefined
                            }
                            aria-expanded={openSendMenu ? "true" : undefined}
                            aria-haspopup="true"
                            disabled={!currentMessage || (currentMessage?.length > 320 && sendOption && props.candidate?.mobileNumber)}
                            aria-label="Menu icon"
                            className="upload-status invite-btn btn primary-button p-0 ml-5"
                            disableRipple="true"
                          >
                            <Typography className="post-button bulk-upload-btn job-detail-matching d-content">
                              <Typography
                                className="position-issue upload-button pr-25 f-14 font-weight-600"
                                onClick={sendMessageOrFile}
                                onKeyPress={sendMessageOrFile}
                                aria-label="Upload job"
                                disableRipple="true"
                                style={{
                                  borderBottomRightRadius: 0,
                                  borderTopRightRadius: 0,
                                }}
                              >
                                Send
                              </Typography>
                            </Typography>
                          </Button>
                          {/*
                            <a href="javascript:void(0)" onClick={sendMessageOrFile} onKeyPress={sendMessageOrFile} tabIndex={0}>
                              <img style={{ width: "16px", cursor: "pointer" }}
                                className
                                src={messageSending}
                                alt="img"
                              /></a> */}
                        </Box>
                      </Typography>
                    </Typography>
                    <Typography className="pt-10 p-absolute chat-picker" style={{ bottom: '60px' }}>
                      {showPicker && (
                        <Picker width="100%" onEmojiClick={onEmojiClick} />
                      )}
                    </Typography>
                  </Typography>
                )}
                {fileName && (
                  <Typography className="pt-10 w-100 align-center messages-file-padding">
                    <Typography className="gray9 f-14 font-weight-400 d-flex justify-end form-control input-group">
                      <Typography
                        className="break-all d-flex word-space"
                      >
                        <>
                          <span className="d-flex f-14 message-pdf gray7 pt-10 pb-10 pl-15 pr-15 align-center ">
                            <Tooltip
                              title={fileName}
                              position="top"
                              trigger="mouseenter"
                              animation="fade"
                              arrow
                            >
                            <span className="textEllipse" style={{ width: "204px" }}>{fileName}</span>
                            </Tooltip>
                            <span
                              className="ml-10 delete-btn align-center d-flex"
                              onClick={() => setOpenRemoveAttach(true)}
                              onKeyPress={() => setOpenRemoveAttach(true)}
                            >
                              <img
                                width="13px"
                                className="cursor-hover"
                                src={deleteIcon}
                                alt="img"
                                tabIndex={0}
                              />
                            </span>
                          </span>
                        </>
                      </Typography>
                    </Typography>

                    <Typography className="d-flex align-right justify-end mt-8">
                      {/* <img
                        style={{ width: "25px", cursor: "pointer" }}
                        onClick={() => setShowPicker((val) => !val)}
                        src={smileIcon}
                        alt="img"
                      />

                      {showPicker && (
                        <Picker
                          pickerStyle={{ width: "100%" }}
                          onEmojiClick={onEmojiClick}
                        />
                      )} */}

                      <Box className="break-all vertical-middle display-flex align-center">
                      <Grid className="ml-10 d-flex align-center">
                            <input
                              id="contained-button-file"
                              ref={attachmentRef}
                              type="file"
                              accept=".png, .jpg, .jpeg, application/pdf, .doc,.docx"
                              style={{ display: "none" }}
                              onChange={(e) => handleFileUpload(e)}
                            />
                            <a
                              href="javascript:void(0)"
                              onClick={activateFileClick}
                              onKeyPress={activateFileClick}
                              tabIndex={0}
                              className="d-flex vertical-middle gray7 text-decoration-none"
                            >
                              <img
                                style={{ width: "16px", height: '16px' }}
                                className="mr-5 cursor-hover"
                                src={attachmentIcon}
                                alt="img"
                              />
                            </a>
                          </Grid>
                        {/* <input
                            id="contained-button-file"
                            type="file"
                            accept=".png, .jpg, .jpeg, application/pdf, .doc,.docx"
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                          />
                          <a href="javascript:void(0)" onClick={activateFileClick} onKeyPress={activateFileClick} tabIndex={0}>
                            <img style={{ width: "16px", cursor: "pointer" }}
                              src={attachmentIcon}
                              alt="img"
                            /></a> */}
                      </Box>
                      <Box>
                        <Button
                          href="javascript:void(0)"
                          onClick={sendMessageOrFile}
                          onKeyPress={sendMessageOrFile}
                          className="btn primary-button ml-5"
                          aria-label="button"
                        >
                          Send
                        </Button>
                        {/*
  <a href="javascript:void(0)" onClick={sendMessageOrFile} onKeyPress={sendMessageOrFile} tabIndex={0}>
    <img style={{ width: "16px", cursor: "pointer" }}
      className
      src={messageSending}
      alt="img"
    /></a> */}
                      </Box>
                    </Typography>
                  </Typography>
                )}
              </Grid>
            )}
            {/* {messages?.length == 0 && !isLoading && (
              <Grid container spacing={1} lg={12} md={12} xs={12}>
                <Grid
                  item
                  lg={5}
                  md={5}
                  xs={12}
                  className="filter-right-align m-auto"
                >
                  <img className="launch-videoImage mt-50" src={noJobs} alt="img"  width="300px"
                      height="300px"/>
                  <Box className="f-20 mt-20 text-center font-weight-600">
                    You don't have any messages for now
                  </Box>
                </Grid>
              </Grid>
            )} */}
          </Grid>
        </Grid>

      <Modal
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content inputcontrasts">
          <Grid container item id="modal-title">
            <Grid item xs={6}>
              <Typography className="f-20 font-weight-600 heading-text-color modalTitle">
                Image Preview
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                align="right"
                className="cursor-hover gray7 icon-button"
              >
                <CloseIcon
                  onClick={() => setOpenPreviewModal(false)}
                  aria-label="Close icon"
                  tabIndex={0}
                  onKeyPress={() => setOpenPreviewModal(false)}
                />
              </Typography>
            </Grid>
          </Grid>
          <form>
            <Grid item id="modal-description">
              <Grid container item columns={12}>
                <Grid item lg={12}>
                  <Grid container item>
                    <Grid item lg={12} md={12} xs={12}>
                      <Grid item lg={12} md={12} xs={12} className="m-auto">
                        <Grid
                          item
                          className="image-cropper-section align-item-center"
                        >
                          {isMobile ? (
                            <div>
                              <img
                                className="w-100"
                                src={currentImage}
                                alt="img"
                              />
                            </div>
                          ) : (
                            <div>
                              <img src={currentImage} alt="img" className="w-100"/>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid id="modal-footer" className="d-flex">
              <Grid item lg={12} md={12} className="align-item-center"></Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <ConfirmDialog
        open={openDeleteChat}
        setOpen={(e) => setOpenDeleteChat(e)}
        dialogTitle="Delete Chat History"
        description={"Are you sure?"}
        handleConfirmYes={() => {
          handleDeleteChatHistory();
        }}
      />
      <ConfirmDialog
        open={openRemoveAttach}
        setOpen={(e) => setOpenRemoveAttach(e)}
        dialogTitle="Delete Attachment "
        description={"Are you sure?"}
        handleConfirmYes={() => {
          handleRemoveAttachment();
        }}
      />
    </>
  );
}
